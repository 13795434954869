import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AwesomeTooltipComponent } from './awesome-tooltip.component';
import { AwesomeTooltipDirective } from './awesome-tooltip.directive';
import { CustomTooltipComponent } from '../custom-tooltip/custom-tooltip.component';
import { SentForSignaturesTooltipComponent } from '../sent-for-signatures-tooltip/sent-for-signatures-tooltip.component';
import { SentForSignaturesTooltipDirective } from './sent-for-signatures-tooltip.directive';

@NgModule({
  declarations: [
    AwesomeTooltipComponent,
    CustomTooltipComponent,
    AwesomeTooltipDirective,
    SentForSignaturesTooltipComponent,
    SentForSignaturesTooltipDirective,
  ],
  imports: [CommonModule],
  exports: [
    AwesomeTooltipComponent,
    CustomTooltipComponent,
    AwesomeTooltipDirective,
    SentForSignaturesTooltipComponent,
    SentForSignaturesTooltipDirective,
  ],
})
export class AwesomeTooltipModule {}
