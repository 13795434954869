<div class="tooltip">

  <div [class.signed]="data?.supervisor" class="tooltip__row">
    <div class="tooltip__name">Supervisor:</div>
    <div class="tooltip__value">{{ data?.supervisor ? 'Signed' : 'Not signed' }}</div>
  </div>
  <div [class.signed]="data?.patient" class="tooltip__row">
    <div class="tooltip__name">Patient:</div>
    <div class="tooltip__value">{{ data?.patient ? 'Signed' : 'Not signed' }}</div>
  </div>
  <div [class.signed]="data?.hha1" class="tooltip__row">
    <div class="tooltip__name">DCW:</div>
    <div class="tooltip__value">{{ data?.hha1 ? 'Signed' : 'Not signed' }}</div>
  </div>

  <ng-container *ngFor="let key of Object.keys(data); ">
    <div *ngIf="(key !== 'patient' && key !== 'supervisor' && key !== 'hha1')" [class.signed]="data?.[key]"
         class="tooltip__row">
      <div class="tooltip__name">DCW:</div>
      <div class="tooltip__value">{{ data?.[key] ? 'Signed' : 'Not signed' }}</div>
    </div>
  </ng-container>
</div>
