import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sent-for-signatures-tooltip',
  templateUrl: './sent-for-signatures-tooltip.component.html',
  styleUrls: ['./sent-for-signatures-tooltip.component.scss'],
})
export class SentForSignaturesTooltipComponent {
  data = null;
  protected readonly Object = Object;

  @Input() set text(value: string) {
    if (value) {
      this.data = JSON.parse(value);
    } else {
      this.data = { supervisor: false, patient: false, hha: false };
    }
  }
}
