import { ConnectedPosition, FlexibleConnectedPositionStrategy, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take, tap } from 'rxjs/operators';
import { SentForSignaturesTooltipComponent } from '../sent-for-signatures-tooltip/sent-for-signatures-tooltip.component';

@Directive({
  selector: '[appSentForSignaturesTooltip]',
})
export class SentForSignaturesTooltipDirective implements OnInit {
  positionsObj: ConnectedPosition = {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
  };
  private mobileRegex: RegExp = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

  private overlayRef: OverlayRef;
  private touched: boolean;

  constructor(
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private overlay: Overlay,
    private router: Router,
  ) {}

  ngOnInit(): void {
    window.addEventListener('scroll', this.hide.bind(this), true);
    document.addEventListener('touchstart', this.onDocumentTouch.bind(this), true);

    const positionStrategy: FlexibleConnectedPositionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([this.positionsObj]);

    this.overlayRef = this.overlay.create({ positionStrategy });
    this.router.events
      .pipe(
        filter((event): boolean => event instanceof NavigationEnd),
        tap(() => this.hide()),
        take(1),
      )
      .subscribe();
  }

  @HostListener('touchstart', ['$event'])
  onMobileTouch(): void {
    this.toggleTooltip();
  }

  @HostListener('mouseenter')
  show(): void {
    if (!this.touched && !this.isMobile()) {
      this.attachTooltip();
    }
  }

  @HostListener('mouseout')
  hide(): void {
    if (!this.isMobile()) {
      this.overlayRef.detach();
    }
  }

  @HostListener('click', ['$event'])
  onClick(): void {
    this.hide();
  }

  private toggleTooltip(): void {
    if (this.overlayRef?.hasAttached()) {
      this.overlayRef.detach();
    } else {
      this.attachTooltip();
    }
  }

  private attachTooltip(): void {
    const component: any = SentForSignaturesTooltipComponent;
    const tooltipPortal: ComponentPortal<any> = new ComponentPortal(component);
    const tooltipRef: ComponentRef<any> = this.overlayRef.attach(tooltipPortal);
    tooltipRef.instance.text = this.elementRef.nativeElement.innerText;
  }

  private onDocumentTouch(event: TouchEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.overlayRef.detach();
    }
  }

  private isMobile(): boolean {
    return this.mobileRegex.test(navigator.userAgent);
  }
}
